


























































































































































































































import { computed, defineComponent, ref } from "@nuxtjs/composition-api";
import { useContent } from "~/composables";
import createFormJSON from "~/helpers/createFormJSON"

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  SfInput,
  SfRadio,
  SfTexarea,
  SfButton,
  SfCheckbox,
  SfLoader,
} from "@storefront-ui/vue";

import { required, email } from "vee-validate/dist/rules";
extend("email", {
  ...email,
  message: "Invalid email",
});

extend("required", {
  ...required,
  message: "This field is required",
});

export default defineComponent({
  name: "ConsultationForm",
  components: {
    SfInput,
    SfTexarea,
    SfRadio,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      showForm: true,
      name: "",
      userEmail: "",
      userPhone: "",
      userEmailErr: false,
      userPhoneErr: false,
      when: "",
      when_specific_days: [],
      when_specific_timeframe: [],
      file: '',
      files: [],
      message: "",
      recaptcha: false,
      xLoading: false,
      settings: null,
    };
  },
  props: {
      source: {
          type: String,
          default: 'other'
      },
  },
  setup() {
    const { submitContact } = useContent()

    return {
        submitContact
    }
  },
  mounted() {
    const constultationTime = localStorage.getItem("consultationTime");
    this.updateWhen(constultationTime ? constultationTime : "when_asap");
    this.settings = JSON.parse(localStorage.getItem('settings'));
  },
  methods: {
    async formAction(e) {

      
      /*
      try {
        const token = await this.$recaptcha.getResponse()
        console.log(`Token: ${token}`)

        const response = await fetch('/api/check-token', {
          method: 'POST',
          body: JSON.stringify({
            token,
            email: this.email,
            password: this.password
          })
        }).then(res => res.json())

        console.log('Server Response: ', response)

        await this.$recaptcha.reset()
      } catch (error) {
        console.log('Login error:', error)
      }
      */


      let formObject = {};
      e.target.elements.forEach(el => {

        if (
            el 
            && el.name
            && el.value
            && el.type != 'file'
            && (
                el.tagName.toLowerCase() == 'input' 
                || el.tagName.toLowerCase() == 'textarea' 
            )
        ) 
        {
            formObject[el.name] = el.value;
        }
      });

      formObject['source'] = this.source
      formObject['when'] = this.when
      formObject['when_specific_days'] = this.when_specific_days
      formObject['when_specific_timeframe'] = this.when_specific_timeframe
      // console.log(formObject)
      // return

      let files = [];
      const __submitContact = this.submitContact;
      
      // if (e.target.file.files && e.target.file.files.length) {

      //   e.target.file.files.forEach((file, i) => {
          
      //     const r = new FileReader()
      //     r.readAsDataURL(file);
      //     r.onload = function() {
  
      //       files.push(r.result);
  
      //       if (i == e.target.file.files.length - 1) {
  
      //         setTimeout(async () => {
                
      //           formObject['files'] = files;
      //           console.log(JSON.stringify(formObject))
      //           // const res = await __submitContact({ formObject: formObject })
      //           // console.log(res);  
      //         }, 100);
      //       }
      //     }
      //   });
      // } else {
      
      const res = await __submitContact({ formObject: formObject })
      if (res == 200) {
          this.showForm = false
      }

      // }

  
    },
    validateField(model, type) {
      switch (type) {
        case "text":
          model;
      }
    },
    updateWhen(when) {
      this.when = when;
      localStorage.setItem("consultationTime", when);
    },
    onFileChanged($event: Event) {
        const target = $event.target as HTMLInputElement;
        if (target && target.files) {
          this.file = URL.createObjectURL(target.files[0]);
        }
    },

    onError (error) {
      // console.log('Error happened:', error)
      this.recaptcha = false
    },

    // async onSubmit() {
    //   try {
    //     const token = await this.$recaptcha.getResponse()

    //     const response = await fetch('/api/check-token', {
    //       method: 'POST',
    //       body: JSON.stringify({
    //         token,
    //         email: this.email,
    //         password: this.password
    //       })
    //     }).then(res => res.json())

    //     console.log('Server Response: ', response)

    //     await this.$recaptcha.reset()
    //   } catch (error) {
    //     console.log('Login error:', error)
    //   }
    // },

    onSuccess (token) {
      // console.log('Succeeded:', token)
      this.recaptcha = true
    },

    onExpired () {
      // console.log('Expired')
      this.recaptcha = false
    }
  },
});
