//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    defineComponent,
} from '@nuxtjs/composition-api';

import VueSlickCarousel from 'vue-slick-carousel'

export default defineComponent ({
    name: 'SlickCarousel',
    components: {
        VueSlickCarousel,
        // SfLink,
        // SfButton
    },
    inject: [
        'image_format'
    ],
    props: {
        content: {
            className: String,
            ctas: {
                type: Array,
                default() {
                    return {
                        title: String,
                        url: String
                    }
                }
            },
            desktopImage: {
                type: Object,
                default() {
                    return {
                        src: String,
                        width: Number,
                        height: Number,
                        alt: String
                    }
                }
            },
            mobileImage: {
                type: Object,
                default() {
                    return {
                        src: String,
                        width: Number,
                        height: Number,
                        alt: String
                    }
                }
            },
            title: String,
            layout: {
                type: String,
                default: 'vertical'
            },
            position: {
                type: Number,
                default: -20
            },
            overlay: {
                type: Object,
                default() {
                    return {
                        src: {
                            type: String,
                            default: 'https://placehold.co/720x1024'
                        },
                        width: {
                            type: Number,
                            default: 720
                        },
                        height: {
                            type: Number,
                            default: 1024
                        },
                        alt: {
                            type: String,
                            default: 'Overlay'
                        }
                    }
                }
            },
            logo: {
                type: Object,
                default() {
                    return {
                        src: 'https://placehold.co/300x300',
                        width: 300,
                        height: 300,
                        alt: 'Logo'
                    }
                }
            },
            fade: {
                type: Boolean,
                default: false
            }
        }
    },
    setup() {
        return {
            settings: {
                dots: true,
                // dotsClass: "slick-dots custom-dot-class",
                // edgeFriction: 0.35,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 6000,
                cssEase: "ease-in-out",
                // prevArrow: '<i class="fa-solid fa-chevron-left"></i>',
                // nextArrow: '<i class="fa-solid fa-chevron-right"></i>',
                // prevArrow: '',
                // nextArrow: '',
                centerMode: true,
                centerPadding: '0px',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            autoplay: true
                        }
                    }
                ]
            },
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    mounted() {
        window.addEventListener("resize", this.resizeHandler)
        this.resizeHandler()
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler)
    },
    methods: {
        onInitCarousel() {
            
        },
        resizeHandler(e) {
            const viewportWidth = parseFloat(window.innerWidth)
            const elements = document.querySelectorAll('.slick-carousel-slider .slick-item .position')
            elements.forEach(element => {
                const position = parseFloat(element.getAttribute('data-position'))
                const style = window.getComputedStyle(element.parentElement)
                const maxWidth = parseFloat(style.maxWidth)
                const leftValue = ( ( viewportWidth/2 - maxWidth/2 ) / 100 * position )
                element.style.left = leftValue + 'px'
            });
        }
    },
});

